/** @jsx jsx */

import { jsx } from './jsx.js';

import { translations } from './translations.js';
import { linkLabels } from './link-labels.js';
import { link } from './domains.js';

export const keys = {
  dp: 'data-protection-notice',
  cp: 'cookies-policy',
  ci: 'cookies-inventory',
};

export const urlBuilder = ({ lang, slug }) =>
  `https://elections.europa.eu/${lang}/${slug}/`;

export const linksBuilder =
  (url) =>
  ({ lang, dataMoreLink }) => {
    const { textBottom1, textBottom2, textBottom3 } = translations(lang);
    const labels = linkLabels[lang];

    const firstLink = [
      textBottom1,
      link(dataMoreLink || url({ lang, slug: keys.dp }), labels[keys.dp]),
    ];

    return dataMoreLink ? (
      <p>{firstLink}</p>
    ) : (
      <p>
        {firstLink}{' '}
        {[textBottom2, link(url({ lang, slug: keys.cp }), labels[keys.cp])]}{' '}
        {[textBottom3, link(url({ lang, slug: keys.ci }), labels[keys.ci])]}{' '}
      </p>
    );
  };

export const links = linksBuilder(urlBuilder);
export const tag = (_) => '';
