export const linkLabels = {
  en: {
    'cookies-inventory': 'cookies inventory',
    'cookies-policy': 'cookies policy',
    'data-protection-notice': 'data protection notice'
  },
  bg: {
    'cookies-inventory': 'списък на бисквитките',
    'cookies-policy': 'политика относно бисквитките',
    'data-protection-notice': 'информация за политиката за защита на личните данни'
  },
  hr: {
    'cookies-inventory': 'popis kolačića',
    'cookies-policy': 'politika o kolačićima',
    'data-protection-notice': 'napomena o zaštiti podataka'
  },
  cs: {
    'cookies-inventory': 'inventář cookies',
    'cookies-policy': 'zásady používání cookies',
    'data-protection-notice': 'prohlášení o ochraně údajů'
  },
  da: {
    'cookies-inventory': 'cookieoversigt',
    'cookies-policy': 'cookiepolitik',
    'data-protection-notice': 'meddelelse om databeskyttelse'
  },
  et: {
    'cookies-inventory': 'küpsiste loetelu',
    'cookies-policy': 'küpsiste kasutamise põhimõtted',
    'data-protection-notice': 'andmekaitseteade'
  },
  fi: {
    'cookies-inventory': 'evästehakemisto',
    'cookies-policy': 'evästekäytäntö',
    'data-protection-notice': 'tietosuojailmoitus'
  },
  fr: {
    'cookies-inventory': 'inventaire des cookies',
    'cookies-policy': 'politique d’utilisation des cookies',
    'data-protection-notice': 'avis relatif à la protection des données'
  },
  ga: {
    'cookies-inventory': 'fardal na bhfianán',
    'cookies-policy': 'beartas maidir le fianáin',
    'data-protection-notice': 'fógra um chosaint sonraí'
  },
  de: {
    'cookies-inventory': 'cookie-verzeichnis',
    'cookies-policy': 'cookie-leitlinien',
    'data-protection-notice': 'erklärung zum datenschutz'
  },
  el: {
    'cookies-inventory': 'κατάλογο cookies',
    'cookies-policy': 'πολιτική cookies',
    'data-protection-notice': 'σημείωμα περί προστασίας των δεδομένων'
  },
  hu: {
    'cookies-inventory': 'sütijegyzék',
    'cookies-policy': 'a sütik használatára vonatkozó szabályzat',
    'data-protection-notice': 'adatvédelmi nyilatkozat'
  },
  it: {
    'cookies-inventory': 'inventario dei cookie',
    'cookies-policy': 'politica sull\'uso dei cookie',
    'data-protection-notice': 'informativa sulla protezione dei dati'
  },
  lv: {
    'cookies-inventory': 'sīkdatņu saraksts',
    'cookies-policy': 'sīkdatņu politika',
    'data-protection-notice': 'paziņojums par datu aizsardzību'
  },
  lt: {
    'cookies-inventory': 'slapukų aprašas',
    'cookies-policy': 'slapukų politika',
    'data-protection-notice': 'įspėjimas apie duomenų apsaugą'
  },
  mt: {
    'cookies-inventory': 'inventarju tal-cookies',
    'cookies-policy': 'il-politika dwar il-cookies',
    'data-protection-notice': 'avviż dwar il-protezzjoni tad-data'
  },
  nl: {
    'cookies-inventory': 'cookies',
    'cookies-policy': 'cookiebeleid',
    'data-protection-notice': 'mededeling gegevensbescherming'
  },
  pl: {
    'cookies-inventory': 'wykaz plików cookie',
    'cookies-policy': 'polityka dotycząca plików cookie',
    'data-protection-notice': 'informacja o ochronie danych'
  },
  pt: {
    'cookies-inventory': 'inventário de cookies',
    'cookies-policy': 'política relativa aos cookies',
    'data-protection-notice': 'declaração relativa à proteção de dados'
  },
  ro: {
    'cookies-inventory': 'inventarul modulelor cookie',
    'cookies-policy': 'politica privind modulele cookie',
    'data-protection-notice': 'aviz privind protecția datelor'
  },
  sk: {
    'cookies-inventory': 'zoznam používaných súborov cookie',
    'cookies-policy': 'podmienky používania súborov cookie',
    'data-protection-notice': 'oznámenie o ochrane údajov'
  },
  sl: {
    'cookies-inventory': 'seznam piškotkov',
    'cookies-policy': 'politika piškotkov',
    'data-protection-notice': 'obvestilo o varstvu podatkov'
  },
  es: {
    'cookies-inventory': 'inventario de cookies',
    'cookies-policy': 'política de cookies',
    'data-protection-notice': 'aviso de protección de datos'
  },
  sv: {
    'cookies-inventory': 'genomgång av kakor',
    'cookies-policy': 'policy för kakor',
    'data-protection-notice': 'meddelande om skydd av personuppgifter'
  }
}

export default linkLabels;