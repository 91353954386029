export const translations = (lang) => data[lang] || data.en;

export const data = {
  en: {
    lang: 'en',
    optOutDesc:
      'Based on the information at our disposal, it seems you refused our analytics cookies. If you change your mind, you may activate the following choice:',
    optInLabel: 'I accept analytics cookies',
    optInDesc:
      'Based on the information at our disposal, it seems you accepted our analytics cookies. If you change your mind, you may activate the following choice:',
    optOutLabel: 'I refuse analytics cookies',
    chooseDesc:
      'Based on the information at our disposal, it seems you neither accepted nor refused our analytics cookies. Consequently, we did not place any on your device. Could you please explicitly express your choice?',
    dntDesc:
      'Because you activated the "Do Not Track" (DNT) setting of your browser, we do not add any analytics cookie on your device. If you change your mind, please de-activate the DNT setting of your browser and refresh your cache.',
    ariaLabel: 'Managing cookies on this website',
  },
  uk: {
    lang: 'uk',
    optOutDesc:
      'Згідно з доступною нам інформацією Ви прийняли наші аналітичні файли cookie. Якщо передумаєте, то можете активувати такий вибір:',
    optOutLabel: 'Я відмовляюся від аналітичних файлів cookie.',
    optInDesc:
      'Згідно з доступною нам інформацією Ви відмовилися від наших аналітичних файлів cookie. Якщо передумаєте, то можете активувати такий вибір:',
    optInLabel: 'Я приймаю аналітичні файли cookie.',
    chooseDesc:
      'Згідно з доступною нам інформацією Ви не прийняли та не відмовилися від наших аналітичних файлів cookie. Тому ми не розміщували їх на Вашому пристрої. Просимо Вас чітко визначитися та зробити свій вибір.',
    dntDesc:
      'Оскільки у своєму браузері Ви активували налаштування «Не відстежувати» (DNT), ми не додаємо жодних аналітичних файлів cookie на Ваш пристрій. Якщо Ви передумаєте, будь ласка, деактивуйте налаштування DNT у своєму браузері та оновіть кеш.',
    ariaLabel: 'Управління файлами cookie на цьому вебсайті',
  },
  bg: {
    lang: 'bg',
    optOutDesc:
      'Въз основа на информацията, с която разполагаме, изглежда сте отхвърлили нашите аналитични бисквитки. Ако промените решението си, можете да задействате следната опция:',
    optInLabel: 'Приемам аналитичните бисквитки',
    optInDesc:
      'Въз основа на информацията, с която разполагаме, изглежда сте приели нашите аналитични бисквитки. Ако промените решението си, можете да задействате следната опция:',
    optOutLabel: 'Отхвърлям аналитичните бисквитки',
    chooseDesc:
      'Въз основа на информацията, с която разполагаме, изглежда нито сте приели, нито сте отхвърлили нашите аналитични бисквитки. Съответно ние не сме поставили никакви бисквитки на Вашето устройство. Бихте ли могли да изразите своя избор изрично?',
    dntDesc:
      'Тъй като задействахте настройката „Не проследявай“ (DNT) на Вашия браузър, не добавяме никакви аналитични бисквитки на Вашето устройство. Ако промените решението си, моля деактивирайте настройката DNT на Вашия браузър и актуализирайте паметта си.',
    ariaLabel: 'Управление на „бисквитките“ на уебсайта',
  },

  cs: {
    lang: 'cs',
    optOutDesc:
      'Na základě informací, které máme k dispozici, se zdá, že nesouhlasíte se zasíláním našich analytických cookies. Pokud změníte názor, můžete aktivovat následující volbu:',
    optInLabel: 'Souhlasím se zasíláním analytických cookies',
    optInDesc:
      'Na základě informací, které máme k dispozici, se zdá, že souhlasíte se zasíláním našich analytických cookies. Pokud změníte názor, můžete aktivovat následující volbu:',
    optOutLabel: 'Nesouhlasím se zasíláním analytických cookies',
    chooseDesc:
      'Na základě informací, které máme k dispozici, se zdá, že jste neučinili žádné rozhodnutí ohledně zasílání analytických cookies. Proto jsme ve vašem zařízení žádné nepoužili. Vyjádřete prosím svůj výslovný souhlas nebo nesouhlas výběrem jedné z možností.',
    dntDesc:
      'Jelikož jste ve svém prohlížeči aktivovali funkci „Do Not Track“ (DNT), nezaslali jsme do vašeho zařízení žádné analytické cookies. Pokud změníte názor, deaktivujte ve vašem prohlížeči funkci DNT a obnovte mezipaměť.',
    ariaLabel: 'Správa souborů cookie na těchto internetových stránkách',
  },

  da: {
    lang: 'da',
    optOutDesc:
      'Ud fra de oplysninger, vi har til rådighed, har du tilsyneladende afvist vores analysecookies. Hvis du skifter mening, kan du vælge følgende:',
    optInLabel: 'Jeg accepterer analysecookies',
    optInDesc:
      'Ud fra de oplysninger, vi har til rådighed, har du tilsyneladende accepteret vores analysecookies. Hvis du skifter mening, kan du vælge følgende:',
    optOutLabel: 'Jeg afviser analysecookies',
    chooseDesc:
      'Ud fra de oplysninger, vi har til rådighed, har du tilsyneladende hverken accepteret eller afvist vores analysecookies. Derfor har vi ikke placeret nogen på din enhed. Du bedes udtrykkeligt angive, hvilket valg du har truffet.',
    dntDesc:
      'Da du har aktiveret din browsers "Do Not Track"-indstilling (spor mig ikke), tilføjer vi ikke nogen analysecookies på din enhed. Hvis du skifter mening, bedes du deaktivere din browsers "Do Not Track"-indstilling og opdatere din cache.',
    ariaLabel: 'Administration af cookies på dette websted',
  },

  de: {
    lang: 'de',
    optOutDesc:
      'Anhand der vorliegenden Informationen haben Sie die Verwendung von Analyse-Cookies des Europäischen Parlaments abgelehnt. Wenn Sie Ihre Meinung ändern, können Sie das folgende Kontrollkästchen aktivieren:',
    optInLabel: 'Analyse-Cookies akzeptieren',
    optInDesc:
      'Anhand der vorliegenden Informationen haben Sie die Verwendung von Analyse-Cookies des Europäischen Parlaments akzeptiert. Wenn Sie Ihre Meinung ändern, können Sie das folgende Kontrollkästchen aktivieren:',
    optOutLabel: 'Analyse-Cookies ablehnen',
    chooseDesc:
      'Anhand der vorliegenden Informationen haben Sie die Verwendung von Analyse-Cookies des Europäischen Parlaments weder akzeptiert noch abgelehnt. Dementsprechend hat das Europäische Parlament keine Cookies auf Ihrem Gerät gesetzt. Bitte treffen Sie hier ausdrücklich eine Auswahl:',
    dntDesc:
      'Da Sie in Ihrem Browser die Einstellung „Do Not Track“ (DNT, „nicht verfolgen“) aktiviert haben, setzt das Europäische Parlament auf Ihrem Gerät keine Analyse-Cookies. Wenn Sie Ihre Meinung ändern, müssen Sie in Ihrem Browser die DNT-Einstellung deaktivieren und den Cache aktualisieren.',
    ariaLabel: 'Verwaltung von Cookies auf dieser Website',
  },

  el: {
    lang: 'el',
    optOutDesc:
      'Με βάση τις πληροφορίες που έχουμε στη διάθεσή μας, φαίνεται ότι αρνηθήκατε τα cookies ανάλυσής μας. Εάν αλλάξετε γνώμη, μπορείτε να ενεργοποιήσετε την ακόλουθη επιλογή:',
    optInLabel: 'Αποδέχομαι τα cookies ανάλυσης',
    optInDesc:
      'Με βάση τις πληροφορίες που έχουμε στη διάθεσή μας, φαίνεται ότι αποδεχθήκατε τα cookies ανάλυσής μας. Εάν αλλάξετε γνώμη, μπορείτε να ενεργοποιήσετε την ακόλουθη επιλογή:',
    optOutLabel: 'Απορρίπτω τα cookies ανάλυσης',
    chooseDesc:
      'Με βάση τις πληροφορίες που έχουμε στη διάθεσή μας, φαίνεται ότι ούτε αποδεχθήκατε ούτε αρνηθήκατε τα cookies ανάλυσής μας. Κατά συνέπεια, δεν τοποθετήσαμε κανένα στη συσκευή σας. Θα θέλατε να δηλώσετε ρητά την επιλογή σας;',
    dntDesc:
      'Επειδή ενεργοποιήσατε τη ρύθμιση «Do Not Track» (DNT) («Μην παρακολουθείτε») στον φυλλομετρητή σας, δεν προσθέτουμε κανένα cookie ανάλυσης στη συσκευή σας. Εάν αλλάξετε γνώμη, απενεργοποιήστε τη ρύθμιση DNT του φυλλομετρητή σας και ανανεώστε την κρυφή μνήμη σας.',
    ariaLabel: 'Διαχείριση των cookies στον παρόντα ιστότοπο',
  },

  es: {
    lang: 'es',
    optOutDesc:
      'Según la información de que disponemos, parece que usted aceptó nuestras cookies de análisis. Si cambia de opinión, puede activar la siguiente opción:',
    optInLabel: 'Acepto las cookies de análisis',
    optInDesc:
      'Según la información de que disponemos, parece que usted rechazó nuestras cookies de análisis. Si cambia de opinión, puede activar la siguiente opción:',
    optOutLabel: 'Rechazo las cookies de análisis',
    chooseDesc:
      'Según la información de que disponemos, parece que usted no aceptó ni rechazó nuestras cookies de análisis. Por lo tanto, no colocamos ninguna en su dispositivo. ¿Podría expresar explícitamente su elección?',
    dntDesc:
      'Al activar usted la configuración de «No realizar seguimiento» de su navegador, no añadimos ninguna cookie de análisis en su dispositivo. Si cambia de opinión, lo rogamos desactive la configuración de «No realizar seguimiento» de su navegador y actualice la memoria caché.',
    ariaLabel: 'Gestión de cookies de este sitio web',
  },

  et: {
    lang: 'et',
    optOutDesc:
      'Meie käsutuses oleva teabe põhjal tundub, et keeldusite meie analüüsiküpsistest. Kui otsustate siiski analüüsiküpsistega nõustuda, võite aktiveerida järgmise valiku:',
    optInLabel: 'Nõustun analüüsiküpsistega',
    optInDesc:
      'Meie käsutuses oleva teabe põhjal tundub, et nõustusite meie analüüsiküpsistega. Kui otsustate siiski analüüsiküpsistest keelduda, võite aktiveerida järgmise valiku:',
    optOutLabel: 'Keeldun analüüsiküpsistest',
    chooseDesc:
      'Meie käsutuses oleva teabe põhjal tundub, et te ei nõustunud meie analüüsiküpsistega ega ka ei keeldunud neist. Seetõttu ei paigaldanud me teie seadmele küpsiseid. Palun väljendage oma valikut selgesõnaliselt.',
    dntDesc:
      'Kuna aktiveerisite oma veebilehitsejas seade „Do Not Track“ (DNT), ei paigalda Euroopa Parlament teie seadmesse ühtegi analüüsiküpsist. Kui otsustate siiski küpsistega nõustuda, siis deaktiveerige oma veebilehitseja DNT seade ja värskendage vahemälu.',
    ariaLabel: 'Küpsiste haldamine',
  },

  fi: {
    lang: 'fi',
    optOutDesc:
      'Käytettävissämme olevien tietojen mukaan olet estänyt analytiikkaevästeemme. Jos muutat mieltäsi, voit valita seuraavan vaihtoehdon:',
    optInLabel: 'Hyväksyn analytiikkaevästeet',
    optInDesc:
      'Käytettävissämme olevien tietojen mukaan olet sallinut analytiikkaevästeemme. Jos muutat mieltäsi, voit valita seuraavan vaihtoehdon:',
    optOutLabel: 'En hyväksy analytiikkaevästeitä',
    chooseDesc:
      'Käytettävissämme olevien tietojen mukaan et ole sallinut etkä estänyt analytiikkaevästeitämme. Näin ollen emme asettaneet niitä laitteellesi. Ilmoita, kumman vaihtoehdon valitset:',
    dntDesc:
      'Koska olet ottanut seuraamisenestoasetuksen (”Do Not Track”) käyttöön selaimessasi, emme lisää laitteellesi analytiikkaevästeitä. Jos muutat mieltäsi, ota DNT-asetus pois käytöstä selaimessasi ja päivitä välimuisti.',
    ariaLabel: 'Evästeiden hallinta tällä sivustolla',
  },

  fr: {
    lang: 'fr',
    optOutDesc:
      'Sur la base des informations dont nous disposons, il semble que vous ayez refusé nos cookies analytiques. Si vous changez d’avis, vous pouvez activer le choix suivant:',
    optInLabel: 'J’accepte les cookies analytiques',
    optInDesc:
      'Sur la base des informations dont nous disposons, il semble que vous ayez accepté nos cookies analytiques. Si vous changez d’avis, vous pouvez activer le choix suivant:',
    optOutLabel: 'Je refuse les cookies analytiques',
    chooseDesc:
      'Sur la base des informations dont nous disposons, il semble que vous n’ayez ni accepté ni refusé nos cookies analytiques. Par conséquent, nous n’avons installé aucun cookie sur votre appareil. Pourriez-vous exprimer votre choix?',
    dntDesc:
      'Comme vous avez activé la fonctionnalité «Ne pas suivre» de votre navigateur, nous n’installons aucun cookie analytique sur votre appareil. Si vous changez d’avis, vous pouvez désactiver la fonctionnalité «Ne pas suivre» de votre navigateur et rafraîchir la mémoire cache.',
    ariaLabel: 'Gérer les cookies',
  },

  ga: {
    lang: 'ga',
    optOutDesc:
      'Bunaithe ar an bhfaisnéis atá ar fáil againn, is cosúil gur dhiúltaigh tú ár bhfianáin anailisíochta. Má athraíonn tú d’intinn, féadfaidh tú an rogha seo a leanas a ghníomhachtú:',
    optInLabel: 'Glacaim le fianáin anailísíochta',
    optInDesc:
      'Bunaithe ar an bhfaisnéis atá ar fáil againn, is cosúil gur ghlac tú lenár bhfianáin anailisíochta. Má athraíonn tú d’intinn, féadfaidh tú an rogha seo a leanas a ghníomhachtú:',
    optOutLabel: 'Diúltaím fianáin anailísíochta',
    chooseDesc:
      'Bunaithe ar an bhfaisnéis atá ar fáil againn, is cosúil nár ghlac tú lenár bhfianáin anailisíochta nó nár dhiúltaigh tú iad. Dá bhrí sin, níl aon fhianáin suiteáilte againn ar do ghléas. An bhféadfá do rogha a chur in iúl?',
    dntDesc:
      'De bharr gur ghníomhaigh tú an socrú “Ná Rianaigh” (DNT) ar do bhrabhsálaí, ní chuireann Parlaimint na hEorpa aon fhianáin anailísíochta le do ghléas. Má athraíonn tú d’intinn, déan socrú DNT do bhrabhsálaí a dhíghníomhachtú agus do thaisce a athnuachan.',
    ariaLabel: 'Fianáin a bhainistiú ar an suíomh gréasáin seo',
  },

  hr: {
    lang: 'hr',
    optOutDesc:
      'Na temelju informacija kojima raspolažemo, zaključujemo da ste odbili analitičke kolačiće. Ako se predomislite, možete odabrati jednu od sljedećih mogućnosti:',
    optInLabel: 'Prihvaćam analitičke kolačiće',
    optInDesc:
      'Na temelju informacija kojima raspolažemo, zaključujemo da ste prihvatili analitičke kolačiće. Ako se predomislite, možete odabrati jednu od sljedećih mogućnosti:',
    optOutLabel: 'Ne prihvaćam analitičke kolačiće',
    chooseDesc:
      'Na temelju informacija kojima raspolažemo, zaključujemo da niste ni prihvatili ni odbili analitičke kolačiće. U skladu s time na vaš uređaj nisu pohranjeni kolačići. Molimo vas da se izjasnite',
    dntDesc:
      'Budući da ste aktivirali opciju „Do Not Track” u svome pregledniku, na vaš uređaj ne dodajemo analitičke kolačiće. Ako se predomislite, deaktivirajte opciju „Do Not Track” u svome pregledniku i osvježite predmemoriju.',
    ariaLabel: 'Upravljanje kolačićima na ovoj stranici',
  },

  hu: {
    lang: 'hu',
    optOutDesc:
      'A rendelkezésünkre álló információk alapján úgy tűnik, hogy Ön elutasította elemzési célú sütijeinket. Ha meggondolja magát, aktiválhatja a következő opciót:',
    optInLabel: 'Elfogadom az elemzési célú sütiket',
    optInDesc:
      'A rendelkezésünkre álló információk alapján úgy tűnik, hogy Ön elfogadta elemzési célú sütijeinket. Ha meggondolja magát, aktiválhatja a következő opciót:',
    optOutLabel: 'Elutasítom az elemzési célú sütiket',
    chooseDesc:
      'A rendelkezésünkre álló információk alapján úgy tűnik, hogy Ön se nem fogadta el, se nem utasította el elemzési célú sütijeinket. Ezért a készülékén nem helyeztünk el sütit. Kérjük, szíveskedjen kifejezetten jelezni választását.',
    dntDesc:
      'Mivel aktiválta böngészőjének nyomon követést tiltó (DNT) beállítását, nem adunk hozzá elemzési célú sütiket az eszközéhez. Ha meggondolja magát, kérjük, oldja fel böngészője DNT-beállítását, és frissítse a gyorsítótárat.',
    ariaLabel: 'Sütik kezelése a weblapon',
  },

  it: {
    lang: 'it',
    optOutDesc:
      'Sulla base delle informazioni a nostra disposizione, sembra che tu abbia rifiutato i nostri cookie analitici. Se cambi idea, puoi attivare la seguente scelta:',
    optInLabel: 'Accetto i cookie analitici',
    optInDesc:
      'Sulla base delle informazioni a nostra disposizione, sembra che tu abbia accettato i nostri cookie analitici. Se cambi idea, puoi attivare la seguente scelta:',
    optOutLabel: 'Rifiuto i cookie analitici',
    chooseDesc:
      'Sulla base delle informazioni a nostra disposizione, sembra che tu non abbia né accettato né rifiutato i nostri cookie analitici. Pertanto, non abbiamo installato alcun cookie sul tuo dispositivo. Ti preghiamo di esplicitare la tua scelta.',
    dntDesc:
      "Poiché hai attivato l'impostazione antitracciamento sul tuo browser, non installiamo alcun cookie analitico sul tuo dispositivo. Se cambi idea, puoi disattivare l'impostazione antitracciamento del tuo browser e aggiornare la memoria cache.",
    ariaLabel: 'Gestione dei cookie su questo sito web',
  },

  lt: {
    lang: 'lt',
    optOutDesc:
      'Remiantis turima informacija panašu, jog nesutikote, kad būtų naudojami analitiniai slapukai. Jei apsigalvosite, galite aktyvuoti šį pasirinkimą:',
    optInLabel: 'Sutinku, kad būtų naudojami analitiniai slapukai',
    optInDesc:
      'Remiantis turima informacija panašu, jog sutikote, kad būtų naudojami analitiniai slapukai. Jei apsigalvosite, galite aktyvuoti šį pasirinkimą:',
    optOutLabel: 'Nesutinku, kad būtų naudojami analitiniai slapukai',
    chooseDesc:
      'Remiantis turima informacija panašu, kad jūs nei davėte sutikimą naudoti analitinius slapukus, nei atsisakėte, kad jie būtų naudojami. Todėl jūsų įrenginyje jų nenaudojame. Ar galėtumėte aiškiai nurodyti, ką pasirinkate?',
    dntDesc:
      'Kadangi aktyvavote naršyklės nuostatą „Nesekti“ (DNT), į jūsų įrenginį nededame analitinių slapukų. Jei persigalvojate, savo naršyklėje išjunkite nuostatą DNT ir atnaujinkite atmintinę.',
    ariaLabel: 'Slapukų tvarkymas šioje svetainėje',
  },

  lv: {
    lang: 'lv',
    optOutDesc:
      'Pamatojoties uz mūsu rīcībā esošo informāciju, šķiet, ka jūs noraidījāt mūsu analītiskās sīkdatnes. Ja pārdomājat, varat aktivizēt šādu izvēli:',
    optInLabel: 'Es piekrītu analītisko sīkdatņu izmantošanai',
    optInDesc:
      'Pamatojoties uz mūsu rīcībā esošo informāciju, šķiet, ka jūs piekritāt mūsu analītisko sīkdatņu izmantošanai. Ja pārdomājat, varat aktivizēt šādu izvēli:',
    optOutLabel: 'Es atsakos no analītiskajām sīkdatnēm',
    chooseDesc:
      'Pamatojoties uz mūsu rīcībā esošo informāciju, šķiet, ka jūs nedz atteicāties no mūsu analītisko sīkdatņu izmantošanas, nedz piekritāt to izmantošanai. Līdz ar to mēs jūsu ierīcē tās neizmantojam. Vai jūs, lūdzu, varētu skaidri paust savu izvēli?',
    dntDesc:
      'Tā kā savā pārlūkprogramma jūs aktivizējāt iestatījumu “Nesekot” (DNT), mēs jūsu ierīcei nepievienojam nevienu analītisko sīkdatni. Ja pārdomājat, deaktivizējiet pārlūkprogrammas DNT iestatījumu un atsvaidziniet kešatmiņu.',
    ariaLabel: 'Šajā tīmekļa vietnē izmantoto sīkdatņu pārvaldība',
  },

  mt: {
    lang: 'mt',
    optOutDesc:
      "Fuq il-bażi tal-informazzjoni għad-dispożizzjoni tagħna, jidher li inti rrifjutat il-cookies analitiċi tagħna. Jekk tbiddel fehmtek, tista' tattiva l-għażla li ġejja:",
    optInLabel: 'Naċċetta l-cookies analitiċi',
    optInDesc:
      "Fuq il-bażi tal-informazzjoni għad-dispożizzjoni tagħna, jidher li inti aċċettajt il-cookies analitiċi tagħna. Jekk tbiddel fehmtek, tista' tattiva l-għażla li ġejja:",
    optOutLabel: 'Nirrifjuta l-cookies analitiċi ',
    chooseDesc:
      "Fuq il-bażi tal-informazzjoni għad-dispożizzjoni tagħna, jidher li inti rrifjutajt il-cookies analitiċi tagħna. Konsegwentement, ma qegħidniex cookies fuq l-apparat tiegħek. Tista' jekk jogħġbok tesprimi b'mod espliċitu l-għażla tiegħek?",
    dntDesc:
      'Minħabba li attivajt il-konfigurazzjoni "Do Not Track" (DNT) tal-brawżer tiegħek, mhux se nżidu cookies analitiċi fuq l-apparat tiegħek. Jekk tbiddel fehmtek, jekk jogħġbok iddiżattiva l-konfigurazzjoni tad-DNT tal-brawżer tiegħek u aġġorna l-memorja tal-cache tiegħek.',
    ariaLabel: "Cookies f'dan is-sit",
  },

  nl: {
    lang: 'nl',
    optOutDesc:
      'Volgens de informatie waarover wij beschikken, heeft u onze analytische cookies geweigerd. Als u van mening verandert, kunt u de volgende optie activeren:',
    optInLabel: 'Ik accepteer analytische cookies',
    optInDesc:
      'Volgens de informatie waarover wij beschikken, heeft u onze analytische cookies geaccepteerd. Als u van mening verandert, kunt u de volgende optie activeren:',
    optOutLabel: 'Ik weiger analytische cookies',
    chooseDesc:
      'Volgens de informatie waarover wij beschikken, heeft u onze analytische cookies noch geaccepteerd, nog geweigerd. We hebben dan ook geen cookies op uw apparaat geplaatst. Kunt u uw keuze uitdrukkelijke bevestigen?',
    dntDesc:
      'Omdat u de “Do Not Track”-optie in uw browser heeft ingeschakeld, plaatsen we geen analytische cookies op uw apparaat. Als u van mening verandert, gelieve dan de “Do Not Track”-optie in uw browser uit te schakelen en het cachegeheugen te wissen.',
    ariaLabel: 'Cookiebeheer voor deze website',
  },

  pl: {
    lang: 'pl',
    optOutDesc:
      'Na podstawie informacji, którymi dysponujemy, wydaje się, że nie zgadzasz się na stosowanie analitycznych plików cookie. Jeśli zmienisz zdanie, możesz dokonać następującego wyboru:',
    optInLabel: 'Zgadzam się na stosowanie analitycznych plików cookie',
    optInDesc:
      'Na podstawie informacji, którymi dysponujemy, wydaje się, że zgadzasz się na stosowanie analitycznych plików cookie. Jeśli zmienisz zdanie, możesz dokonać następującego wyboru:',
    optOutLabel: 'Nie zgadzam się na stosowanie analitycznych plików cookie',
    chooseDesc:
      'Na podstawie informacji, którymi dysponujemy, wydaje się, że nie dokonałeś wyboru w odniesieniu do stosowania analitycznych plików cookie. W związku z tym nie dodaliśmy żadnych analitycznych plików cookie do twojego urządzenia. Czy możesz dokonać wyraźnego wyboru?',
    dntDesc:
      'W związku z włączeniem funkcji „Bez śledzenia” w twojej przeglądarce nie dodajemy żadnego analitycznego pliku cookie do twojego urządzenia. W przypadku zmiany zdania proszę wyłączyć funkcję „Bez śledzenia” w ustawieniach przeglądarki i odświeżyć pamięć podręczną.',
    ariaLabel: 'Zarządzanie plikami cookie na tej stronie',
  },

  pt: {
    lang: 'pt',
    optOutDesc:
      'Segundo a informação de que dispomos, parece ter recusado os nossos cookies analíticos. Se mudar de ideias, poderá ativar a seguinte escolha:',
    optInLabel: 'Aceito cookies analíticos',
    optInDesc:
      'Segundo a informação de que dispomos, parece ter aceite os nossos cookies analíticos. Se mudar de ideias, poderá ativar a seguinte escolha:',
    optOutLabel: 'Recuso cookies analíticos',
    chooseDesc:
      'Segundo a informação de que dispomos, parece não ter aceite nem recusado os nossos cookies analíticos. Como tal, não instalámos nenhum cookie analítico no seu dispositivo. Pode indicar explicitamente a sua escolha?',
    dntDesc:
      'Uma vez que ativou a opção «não monitorizar» (Do Not Track - DNT) do seu navegador, não instalámos nenhum cookie analítico no seu dispositivo. Se mudar de ideias, desative a opção de DNT do seu navegador e atualize a cache.',
    ariaLabel: 'Gestão de cookies neste website',
  },

  ro: {
    lang: 'ro',
    optOutDesc:
      'Pe baza informațiilor de care dispunem, se pare că ați refuzat cookie-urile noastre de analiză. Dacă vă răzgândiți, puteți activa următoarea opțiune:',
    optInLabel: 'Accept cookie-urile de analiză',
    optInDesc:
      'Pe baza informațiilor de care dispunem, se pare că ați acceptat cookie-urile noastre de analiză. Dacă vă răzgândiți, puteți activa următoarea opțiune:',
    optOutLabel: 'Refuz cookie-urile de analiză',
    chooseDesc:
      'Pe baza informațiilor de care dispunem, se pare că nu ați acceptat și nici nu ați refuzat cookie-urile noastre de analiză. Prin urmare, nu am folosit niciun cookie pe dispozitivul dvs. Vă rugăm să vă exprimați în mod explicit opțiunea.',
    dntDesc:
      'Deoarece ați activat setarea „Do Not Track” (DNT) a browserului, nu adăugăm niciun cookie de analiză pe dispozitivul dvs. Dacă vă răzgândiți, vă rugăm să dezactivați setarea DNT a browserului dvs. și să actualizați memoria cache.',
    ariaLabel: 'Gestionarea cookie-urilor pe acest site',
  },

  sk: {
    lang: 'sk',
    optOutDesc:
      'Na základe nám dostupných informácií ste odmietli používanie analytických súborov cookie. Ak zmeníte názor, môžete aktivovať ich používanie kliknutím na:',
    optInLabel: 'Súhlasím s používaním analytických súborov cookie.',
    optInDesc:
      'Na základe nám dostupných informácií ste súhlasili s používaním analytických súborov cookie. Ak zmeníte názor, môžete deaktivovať ich používanie kliknutím na:',
    optOutLabel: 'Nesúhlasím s používaním analytických súborov cookie.',
    chooseDesc:
      'Na základe nám dostupných informácií ste neurobili žiadne rozhodnutie týkajúce sa používania analytických súborov cookie. Preto na vašom zariadení nebudú použité. Vyjadrite svoj výslovný súhlas alebo nesúhlas výberom jednej z možností:',
    dntDesc:
      'Keďže ste si v prehliadači aktivovali nastavenie „Do Not Track“ (DNT – nesledovať), Európsky parlament do vášho zariadenia žiadne analytické súbory cookie neposiela. Ak zmeníte názor, v prehliadači si deaktivujte nastavenie DNT a obnovte vyrovnávaciu pamäť.',
    ariaLabel: 'Spravovanie súborov cookie na tomto webovom sídle',
  },

  sl: {
    lang: 'sl',
    optOutDesc:
      'Na podlagi razpoložljivih informacij sklepamo, da ste zavrnili naše analitične piškotke. Če si premislite, lahko aktivirate naslednjo nastavitev:',
    optInLabel: 'Sprejmi analitične piškotke',
    optInDesc:
      'Na podlagi razpoložljivih informacij sklepamo, da ste sprejeli naše analitične piškotke. Če si premislite, lahko aktivirate naslednjo nastavitev:',
    optOutLabel: 'Zavrni analitične piškotke',
    chooseDesc:
      'Na podlagi razpoložljivih informacij sklepamo, da niste niti sprejeli niti zavrnili naših analitičnih piškotkov. Na vašo napravo zato nismo namestili nobenih piškotkov. Prosimo vas, da izrecno navedete svojo izbiro.',
    dntDesc:
      'Ker ste aktivirali nastavitev brskalnika „Ne sledi“ (DNT), na vašo napravo ne bomo dodali analitičnih piškotkov. Če si premislite, morate v brskalniku deaktivirati nastavitev DNT in osvežiti predpomnilnik.',
    ariaLabel: 'Upravljanje piškotkov na tem spletišču',
  },

  sv: {
    lang: 'sv',
    optOutDesc:
      'Utifrån den information vi har tycks du ha valt att avvisa våra analytiska kakor. Om du ändrar dig kan du aktivera följande alternativ:',
    optInLabel: 'Jag godkänner analytiska kakor',
    optInDesc:
      'Utifrån den information vi har tycks du ha valt att godkänna våra analytiska kakor. Om du ändrar dig kan du aktivera följande alternativ:',
    optOutLabel: 'Jag avvisar analytiska kakor',
    chooseDesc:
      'Utifrån den information vi har tycks du varken ha godkänt eller avvisat våra analytiska kakor. Vi har därför inte placerat några sådana kakor på din enhet. Vänligen ange uttryckligen ditt val',
    dntDesc:
      'Eftersom du har aktiverat ”Spåra inte”-inställningen i din webbläsare har vi inte placerat några analytiska kakor på din enhet. Om du ändrar dig kan du avaktivera ”Spåra inte”-inställningen i din webbläsare och uppdatera din cache.',
    ariaLabel: 'Hantering av kakor på den här webbplatsen',
  },
};

export default data;
